import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const styles = {
  link: {
    display: 'block',
    padding: '10px 20px',
    margin: '10px 0',
    borderRadius: '4px',
    textDecoration: 'none',
    color: '#ffffff', // Text color
    backgroundColor: '#007BFF', // Blue background
    fontWeight: 'bold',
    textAlign: 'center',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: '#0056b3', // Darker blue on hover
      color: '#e0e0e0', // Light grey on hover
    },
    width: '200px', // Fixed width
    marginLeft: 'auto', // Center link horizontally
    marginRight: 'auto', // Center link horizontally
  },
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center links horizontally
  },
  heading: {
    marginBottom: '20px',
    color: '#333333', // Dark grey for heading
  },
};

const Links = () => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h5" sx={styles.heading}>
        Navigation
      </Typography>
      <Link to="/CardScanner" style={styles.link}>CardScanner</Link>
      <Link to="/CardProcessor" style={styles.link}>CardProcesser</Link>
      {/* <Link to="/LawnSignScanner" style={styles.link}>LawnSignScanner</Link>
      <Link to="/LawnSignReport" style={styles.link}>LawnSignReport</Link> */}
      <Link to="/LawnSignImageScanner" style={styles.link}>LawnSignImageScanner</Link>
      <Link to="/LawnSignImageData" style={styles.link}>LawnSignImageData</Link>
      <Link to="/view-sp" style={styles.link}>ServiceProviders</Link>
      <Link to="/query" style={styles.link}>SavedQueries</Link>
    </Box>
  );
};

export default Links;
