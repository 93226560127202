import React from 'react'
import {Grid2, Box } from '@mui/material'
import HomeSlider from '../Components/HomeSlider'
import Footer from '../Components/Footer'
import TypographyComponent from '../Components/TypographyComponent'
import Colors from '../Components/Colors'
import { useNavigate } from 'react-router-dom';
import ReviewSlider from '../Components/ReviewSlider'





function Home() {

    const navigate = useNavigate();

  const handleBoxClick = (label) => {
    navigate(`/services?focus=${label}`);
  };
    
  return (
    <Grid2 container sx={{width: '100%', height: '100%',overflow:'hidden', justifyContent:'center', alignItems:'center' }}>

    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>    
       <HomeSlider />   
    </Box> 
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%',height: '96%', maxWidth: '95%',
    paddingTop: '2%', paddingBottom: '2%',gap:'16px' }}>
        <Box sx={{ width: '100%',  height: 'fit-content', justifyContent: 'center', alignItems: 'center',
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: {xs:'16px', md:'24px'}, }}>
            {[
            { label: 'PPC' },
            { label: 'SEO' },
            { label: 'Social media marketing' },
            { label: 'Content creation' },
            { label: 'Email marketing' },
            { label: 'UI-UX designing' },
            { label: 'UI-UX audit' },
            { label: 'Lorem ipsum' },
            ]
            .filter(item => item.label)
            .map((item, index) => (
                <Box key={index} onClick={() => handleBoxClick(item.label)}
                sx={{display: 'flex', alignItems: 'center',justifyContent: 'center',
                width: { xs: 'calc(50% - 16px)', md: 'calc(25% - 24px)' }, height:{xs:'62px', md: '110px'}, background: '#F1F1F1',
                padding: '8px', boxSizing: 'border-box', cursor: 'pointer', borderRadius: '8px',
                '&:hover': { background: Colors.Red, color: Colors.white, '& .typography': {
                    color: Colors.white, } }}}>
                <TypographyComponent
                    className="typography" sx={{ fontSize:{xs:'12px', md: '20px'} , color: 'inherit'}} >
                    {item.label}
                </TypographyComponent>
                </Box>
            ))}
        </Box>

        <Box sx={{ width: 'calc(100% - 24px)',  height: '100%', justifyContent: 'left', alignItems: 'left',
            display: 'flex', flexDirection: 'column', gap: '8px',padding:'12px', }}>
                <TypographyComponent sx={{fontSize:{xs:'18px', md:'32px'}, fontWeight:500, lineHeight:{xs:'22px', md:'38px'},
                textAlign:'left'}}>Who we are</TypographyComponent>
                <TypographyComponent component="h2" sx={{lineHeight:'24px'}} >At Helping Flocks, we are more than just a digital marketing agency; we are a collective of passionate innovators, strategists, and creators dedicated to elevating brands in the digital space. Our team is driven by a shared mission: to help businesses of all sizes find their voice, reach their audience, and achieve their full potential online. We believe in the power of connection, the importance of creativity, and the impact of data-driven decisions. Every member of our team brings unique skills and perspectives to the table, ensuring that our strategies are not only effective but also infused with originality and insight.
we’re a diverse team of digital marketing experts united by a common passion: empowering brands to thrive in the online world. Our collective experience spans across industries, allowing us to bring a fresh perspective and innovative solutions to every project we undertake. We understand that no two brands are the same, and that’s why we tailor our approach to fit the unique needs and goals of each client.
Our team is comprised of strategists, designers, analysts, and content creators who work collaboratively to craft campaigns that not only resonate with your audience but also deliver measurable results. We combine creativity with data-driven insights to ensure that every marketing decision we make is informed, impactful, and aligned with your brand’s vision. At Helping Flocks, we don’t just follow trends; we set them.
Above all, we believe in building strong, lasting relationships with our clients. We see ourselves as an extension of your team, working alongside you to navigate the ever-changing digital landscape. With Helping Flocks, you’re not just getting a service; you’re gaining a partner dedicated to helping your brand soar to new heights.
</TypographyComponent>
        </Box>

        <Box sx={{ width: 'calc(100% - 24px)',  height: '100%', justifyContent: 'left', alignItems: 'left',
            display: 'flex', flexDirection: 'column', gap: '8px',padding:'12px', }}>
                <TypographyComponent sx={{fontSize:{xs:'18px', md:'32px'}, fontWeight:500, lineHeight:{xs:'22px', md:'38px'},
                textAlign:'left'}}>Why choose helping flocks?</TypographyComponent>
                <TypographyComponent  sx={{lineHeight:'24px'}} >In a world saturated with digital noise, Helping Flocks stands out as a beacon of clarity and purpose. We believe in the power of collaboration and the unique potential of every brand. Here’s why Helping Flocks is the right choice for your digital marketing needs:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Tailored strategies: 
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >We don’t believe in one-size-fits-all. Every brand has its own story, and we craft personalized marketing strategies that align with your goals and resonate with your target audience.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Experienced team:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Our team of seasoned marketers, designers, and strategists bring years of industry experience to the table. We stay ahead of the curve, using the latest tools and  A  trends to ensure your brand not only competes but leads.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Results-driven:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >At Helping Flocks, results matter. We’re dedicated to turning your vision into measurable success, whether it’s boosting your online presence, increasing conversions, or fostering customer loyalty.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Collaborative approach:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Your success is our success. We work closely with you, listening to your needs and integrating your insights into every step of our process. Together, we create a  marketing plan that truly reflects your brand’s values and objectives.
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px',fontSize:{xs:'18px', md:'20px'}, fontWeight:500, }} >Ethical marketing:
                </TypographyComponent>
                <TypographyComponent sx={{lineHeight:'24px'}} >Integrity is at the heart of everything we do. We prioritize ethical marketing practices, ensuring that your brand grows in a way that is both sustainable and authentic.
                Choose Helping Flocks, and let’s make your brand’s journey as impactful as it is inspiring.
                </TypographyComponent>
        </Box>

        <Box sx={{ width: 'calc(100% - 24px)',  height: '100%', justifyContent: 'left', alignItems: 'left',
            display: 'flex', flexDirection: 'column', gap: '8px',padding:'12px', }}>
                <TypographyComponent sx={{fontSize:{xs:'18px', md:'32px'}, fontWeight:500, lineHeight:{xs:'22px', md:'38px'},
                textAlign:'left'}}>What our clients say</TypographyComponent>
                <ReviewSlider></ReviewSlider>
               
        </Box>

    </Box>
 
   
   <Footer currentPageFooter={'Home'}></Footer>

   


  

     
    
    </Grid2>

)
}

export default Home