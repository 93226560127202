import React from "react"
import Button from '@mui/material/Button';
import Colors from "./Colors";

 function ButtonRed({ onClick, sx, ...props }){
    
    return(
        <Button variant="contained" 
           onClick={onClick} 
            sx={{boxShadow: 'none',width:'100%', height:{ xs: '28px', md:  '40px' }, backgroundColor: Colors.Red,
            borderRadius:'24px', color: Colors.white, textTransform: 'none',fontFamily: 'SF Pro Display',padding:{ xs: '8px 12px', md: '0px 10px' },
            fontSize:{ xs: '10px', md: '14px' }, fontWeight:'400',border:'1px solid ',borderColor:Colors.Red,
            '&:hover': {
                background: Colors.hovering_red, textTransform: 'none', color: Colors.white, boxShadow: 'none',
              },
              ...sx,
            }}
                 {...props}>            
         </Button>
    )
}
export default ButtonRed;

