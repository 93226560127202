import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import { API_URL } from '../components/Constants';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const LawnSignScanner = () => {
    const [capturedImages, setCapturedImages] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isVideoActive, setIsVideoActive] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const capturedImagesRef = useRef(null);
    const [visibleImages, setVisibleImages] = useState({});
    const [comments, setComments] = useState([]);
    const navigate = useNavigate();
    
    const handleCommentChange = (index, event) => {
        const newComments = [...comments];
        newComments[index] = event.target.value; // Update the specific row's comment
        console.log("updating comments",newComments)
        setComments(newComments);
    };
    useEffect(() => {
        // Update comments whenever tableData changes
       
        setComments(tableData.map(item => item[2] || ''));
       
    }, [tableData]);

    useEffect(() => {
        if (isVideoActive) {
            startVideo();
        }
        fetchData();
    }, [isVideoActive]);

    const startVideo = () => {
        const constraints = {
            video: { facingMode: isVideoActive ? 'user' : 'environment' }
        };

        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                videoRef.current.srcObject = stream;
            })
            .catch(err => {
                console.error('Error accessing the camera: ', err);
            });
    };

    const fetchData = () => {
        fetch(`${API_URL}/displaynewview`)
            .then(response => {
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data)) {
                    console.log("table",data)
                    setTableData(data);
                } else {
                    console.error('Data is not an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const updateComment = ( index,comment) => {
console.log("inddddddd",index,comment)
        console.log("comment index...........",comments)
        fetch(`${API_URL}/update_comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: index, comment: comment }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                alert('Comment updated successfully!');
                fetchData();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Failed to update comment.');
            });
    };

    const toggleImage = (rowIndex, imageIndex) => {
        setVisibleImages(prevVisibleImage =>
            prevVisibleImage.index === rowIndex && prevVisibleImage.imageIndex === imageIndex
                ? { index: null, imageIndex: null }  // Hide image if it's already visible
                : { index: rowIndex, imageIndex }    // Show new image
        );
    };

    
    const handleCapture = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/jpeg');

        setCapturedImages([...capturedImages, dataURL]);
        const imgElement = document.createElement('img');
        imgElement.src = dataURL;
        imgElement.style.maxWidth = '100%';
        imgElement.style.height = 'auto';
        capturedImagesRef.current.appendChild(imgElement);
    };

    const handleFinish = () => {
        if (capturedImages.length === 0) return;

        const formData = new FormData();
        capturedImages.forEach((dataURL, index) => {
            const blob = dataURItoBlob(dataURL);
            formData.append('images', blob, `image_${index}.jpg`);
        });

        fetch(`${API_URL}/save_with_ocr_and_gpt_response`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                return response.json();
            })
            .then(data => {
                console.log('Response Data:', data);
                if (Array.isArray(data)) {
                    alert('Finished');
                } else {
                    console.error('Error: Expected an array but got:', data);
                }
            })
            .catch(error => console.error('Error:', error));
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    return (
        <Box sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
           <Box sx={{ display: 'flex', alignItems: "flex-start", width: 'calc(100% - 4%)', paddingLeft: '4%' }}>
        <IconButton color="primary" onClick={() => navigate('/links')}>
          <HomeIcon />
        </IconButton>
      </Box>
            {!isVideoActive ? (
                <Button onClick={() => setIsVideoActive(true)} variant="contained" color="primary" sx={{ mx: 1 }}>
                    Start Video
                </Button>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                    <Box display="flex" justifyContent="center" mb={3}>
                        <video ref={videoRef} autoPlay muted style={{ width: '100%', maxWidth: '500px' }}></video>
                    </Box>
                    <Box display="flex" justifyContent="center" mb={3}>
                        <Button onClick={handleCapture} variant="contained" color="primary" sx={{ mx: 1 }}>Capture Image</Button>
                        <Button onClick={() => setIsVideoActive(false)} variant="contained" color="error" sx={{ mx: 1 }}>Stop Video</Button>
                    </Box>
                </Box>
            )}

            <Box ref={capturedImagesRef} display="flex" flexDirection="column" alignItems="center"></Box>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

            <Button onClick={handleFinish} variant="contained" sx={{ mt: 2 }}>Finish</Button>

            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '28px', fontWeight: 600 }} gutterBottom mt={4}>Stored Table Details</Typography>

            <TableContainer component={Paper} sx={{ paddingLeft: '4%', paddingRight: '4%', width: '92%' }}>
                <Table sx={{ border: '1px solid black' }}>
                    <TableHead sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600 }}>
                        <TableRow sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, background: 'grey' }}>
                            <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '18px', color: 'white', fontWeight: 600 }}>Select</TableCell>
                            <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: 'white' }}>Id</TableCell>
                            <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: 'white' }}>Image</TableCell>
                            <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: 'white' }}>Created</TableCell>
                            <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: 'white' }}>Comment</TableCell>
                            <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontWeight: 600, color: 'white' }}>Post Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((item, index) => (
                            <TableRow key={index} sx={{
                                '&:nth-of-type(odd)': {
                                    backgroundColor: '#F3F3F3',
                                },
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}>
                                <TableCell>
                                    <Checkbox />
                                </TableCell>
                                <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400 }}>{item[3]}</TableCell>
                                <TableCell>
                                    <Box display="flex" flexDirection="column">
                                        {item[0] ? item[0].split(',').map((location, i) => (
                                            <Box key={i} display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
                                            <Button
                                            variant='outlined'
                                                onClick={() => toggleImage(index, i)}
                                                // color="secondary"
                                                sx={{fontFamily:'Montserrat',fontSize:'12px',minWidth:'120px',
                                                    padding:'4px',fontWeight:400,background:'0063cc',
                                                '&:hover':{fontFamily:'Montserrat',fontSize:'12px',background:'0063cc',
                                                    padding:'4px',fontWeight:400}}}
                                            >
                                                Show Image {i + 1}
                                            </Button>
                                            {visibleImages.index === index && visibleImages.imageIndex === i && (
                                                <Box mt={1} ml={2}> {/* Margin added above the image */}
                                                    <img
                                                        src={location.trim()}
                                                        alt={`Image ${i + 1}`}
                                                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                        )) : "No Image Available"}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400 }}>{item[1]}</TableCell>
                                <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400 }}>
                                <textarea
                            value={comments[index]} // Bind textarea value to state
                            onChange={(event) => handleCommentChange(index, event)} // Update state on change
                            style={{ width: '100%', height: '60px' }} // Optional styling for textarea
                        />
                                </TableCell>
                                <TableCell sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 400 }}>
                                    <Button variant='outlined' onClick={() => updateComment(item[3], item[2])} sx={{
                                        fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400,
                                        '&:hover': { padding: '4px', fontWeight: 400 }
                                    }}>
                                        Post Comment
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default LawnSignScanner;
