


import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Avatar, Rating, IconButton } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TypographyComponent from './TypographyComponent';
import { useMediaQuery } from '@mui/material';
import jen from '../Images/jen.png';
import lin from '../Images/lin.png';
import alb from '../Images/alb.png';
import kir from '../Images/kir.png';
import kira from '../Images/kira.png';
import Colors from './Colors';
import arrowleft from '../Images/arrowleft.svg';
import arrowright from '../Images/arrowright.svg';


const carouselOverlayStylesReviews = `
    .carousel-item-review {
        position: relative;
        width: 100%;
        height: 340px;
        overflow: hidden;
    }

    .carousel-overlay-review {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color:#ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
        z-index: 1;
    }

    @media (max-width: 960px) {
        .carousel-item-review {
            height: 400px;
        }
    }

    @media (max-width: 600px) {
        .carousel-item-review {
            height: 820px;
        }
    }

    .carousel-image {
        width: 100%;
        height: 100%;
         object-fit: '100% 100%;
    }

    .slick-dots-review {
        position: absolute;
        bottom: -16px;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        z-index: 2;
    }

    .slick-dots-review li {
        margin: 0 5px;
        display: inline-block;
    }

    .slick-dots-review li button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background-color: #132436A1;
        cursor: pointer;
        outline: 'none';
    }

    .slick-dots-review li.slick-active button {
        background-color: #DD223F;
    }

    .slick-dots-review li button:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .slick-dots-review li.slick-active button:before {
        opacity: 1;
    }
`;

const carouselDataReview = [
    { image: jen, name:'Jennifer Lopez', rating:4, review: "We came to Helping Flocks with a complex challenge, and they delivered beyond our expectations. Their team developed creative marketing solutions that not only addressed our immediate needs but also set us up for long-term success. Their expertise in digital strategy is evident, and we’ve seen a marked improvement in our brand visibility and customer acquisition. We highly recommend their services!" },
    { image: lin, name:'Linta Thomas', rating:4, review:"Helping Flocks isn't just a marketing agency; they're an extension of our team. Their collaborative approach and dedication to our success have made them an invaluable partner. From social media campaigns to email marketing, they've consistently delivered quality work that aligns with our brand values. We trust them implicitly to guide our digital marketing efforts." },
    { image: kir, name:'Kiran', rating:4, review:"I've worked with several digital marketing agencies, but none have impressed me as much as Helping Flocks. Their creativity, attention to detail, and commitment to results are unparalleled. They helped us launch a new product line with a campaign that exceeded all our expectations in terms of engagement and sales. We look forward to continuing our successful partnership with them" },
    { image: kira, name:'Kiran', rating:4, review:"Helping Flocks has been instrumental in our digital transformation. Their team provided us with exceptional service, from the initial consultation to the execution of our campaigns. They were always available to answer our questions and made the entire process seamless. The results speak for themselves – we’ve seen a significant boost in our online presence and customer engagement." },
    { image: alb, name:'Albert', rating:3, review:"We came to Helping Flocks with a complex challenge, and they delivered beyond our expectations. Their team developed creative marketing solutions that not only addressed our immediate needs but also set us up for long-term success. Their expertise in digital strategy is evident, and we’ve seen a marked improvement in our brand visibility and customer acquisition. We highly recommend their services!" },
];


const PrevArrow = ({ onClick }) => (    
    <IconButton
    onClick={onClick}
    sx={{
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: { xs: '-16px', md: '10px' },
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: Colors.black_80,
        background: `url(${arrowleft}) no-repeat center`,
        backgroundSize: 'contain',
        width: { xs: '24px', md: '36px' },  // Adjust size as needed
        height: { xs: '24px', md: '36px' }, // Adjust size as needed
        '&:hover': {
            background: `url(${arrowleft}) no-repeat center`,
            backgroundSize: 'contain',
        },
    }}
/>
);

const NextArrow = ({ onClick }) => (
  
    <IconButton
    onClick={onClick}
    sx={{
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: { xs: '-16px', md: '10px' },
        transform: 'translateY(-50%)',
        zIndex: 2,
        color: Colors.black_80,
        background: `url(${arrowright}) no-repeat center`,
        backgroundSize: 'contain',
        width: { xs: '24px', md: '36px' },
        height:  { xs: '24px', md: '36px' },
        '&:hover': {
            background: `url(${arrowright}) no-repeat center`,
            backgroundSize: 'contain',
        },
    }}
/>
);

const ReviewSlider = () => {
    const isDesktop = useMediaQuery('(min-width:768px)');

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        appendDots: dots => <ul className="slick-dots-review">{dots}</ul>,
        customPaging: i => <button className="slick-dot-review" />
    };

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Slider {...settings}>
                    {carouselDataReview.map((slide, index) => (
                        <div key={index} className="carousel-item-review">
                            <Box className="carousel-overlay-review" sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                height: 'fit-content',
                                justifyContent: 'center',paddingBottom: { xs:'56px', md: '64px'},paddingTop: { xs:'12px', md: '24px' },
                                alignItems: 'center',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: { xs: 'calc(100% - 16px)', md:  'calc(100% - 48px)' },
                                    height: 'fit-content',
                                    maxWidth: { xs: '80%', md: '60%' },
                                    gap: { xs: '16px', md: '24px' },
                                    backgroundColor: '#FBFBFB',
                                    borderRadius: '32px',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    padding: { xs: '20px 16px', md: '60px 32px' }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: { xs: '52px', md: '128px' },
                                        height: { xs: '52px', md: '128px' },
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start'
                                    }}>
                                        <Avatar alt={slide.name} src={slide.image} sx={{ width: '100%', height: '100%' }} />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: { xs: 'calc(100% - 52px)', md: 'calc(100% - 128px)' },
                                        height: { xs: 'calc(100% - 16px)', md: 'calc(100% - 20px)' },
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: '8px', 
                                    }}>
                                        <TypographyComponent sx={{ textAlign: 'left' }}>
                                            {slide.review}
                                        </TypographyComponent>
                                        <Rating
                                            name="readOnly"
                                            readOnly
                                            size={isDesktop ? 'large' : 'small'}
                                            value={parseInt(slide.rating)}
                                            sx={{ color: '#ffc107' }}
                                            emptyIcon={<StarBorderIcon style={{ color: '#ffc107' }} fontSize="inherit" />}
                                        />
                                        <TypographyComponent sx={{
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: { xs: '12px', md: '20px' },
                                            fontWeight: 500
                                        }}>
                                            {slide.name}
                                        </TypographyComponent>
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    ))}
                </Slider>
            </Box>
        </>
    );
};

export default ReviewSlider;
