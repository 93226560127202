import { Box, Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import {API_URL} from '../components/Constants'
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';



const TruncatedText = ({ text, maxLength = 30 ,boxWidth = 300}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box onClick={handleToggle} style={{ cursor: 'pointer', maxWidth: boxWidth }}>
            {!isExpanded ? (
                <Typography noWrap sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>
                    {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
                </Typography>
            ) : (
                <Box
                    sx={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        padding: '10px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '4px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        maxWidth: boxWidth
                    }}
                >
                    {text}
                </Box>
            )}
        </Box>
    );
};

const CardScanner = () => {
    const [capturedImages, setCapturedImages] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const capturedImagesRef = useRef(null);
    const [visibleImage, setVisibleImage] = useState({ index: null, imageIndex: null });
    const [isVideoActive, setIsVideoActive] = useState(false);
    const navigate = useNavigate(); 
    const [ocrTexts, setOcrTexts] = useState('');
    const [s3Urls, setS3Urls] = useState([]);

    const handleCheckboxChange = (index) => {
        setSelectedItems(prevSelected =>
            prevSelected.includes(index)
                ? prevSelected.filter(item => item !== index)
                : [...prevSelected, index]
        );
    };

    useEffect(() => {
        if (isVideoActive) {
            startVideo();
        }
    }, [isVideoActive]);

    useEffect(() => {
        // startVideo();
        fetchData();
    }, []);

    const startVideo = () => {
        const constraints = {
            video: { facingMode: 'environment' }
        };

        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                videoRef.current.srcObject = stream;
            })
            .catch(err => {
                console.error("Error accessing the camera: ", err);
            });
    };

    const captureImage = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/jpeg');
         const blob = dataURItoBlob(dataURL);
        const sizeInKB = (blob.size / 1024).toFixed(2); // Get size in KB

        // Log the size of the captured image
        console.log("Captured Image Size:", sizeInKB, "KB");
        setCapturedImages(prev => [...prev, dataURL]);

        const imgElement = document.createElement('img');
        imgElement.src = dataURL;
        imgElement.style.maxWidth = '100%';
        imgElement.style.height = 'auto';
        capturedImagesRef.current.appendChild(imgElement);
    };

    const saveImages = () => {
        if (capturedImages.length === 0) return;

        const formData = new FormData();
        capturedImages.forEach((dataURL, index) => {
            const blob = dataURItoBlob(dataURL);
            formData.append('images', blob, `image_${index}.jpg`);
        });

        fetch(`${API_URL}/save`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    console.log("Response Data:", data);
                    // Handle S3 URLs or other responses
                } else {
                    console.error("Error: Expected an array but got:", data);
                }
                alert('Finished');
            })
            .catch(error => console.error('Error:', error));
    };

    
    const fetchData = () => {
        fetch(`${API_URL}/display`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data)) {
                    setImageData(data);
                    console.log("Data from backend:", data);
                } else {
                    console.error('Expected data to be an array, but got:', data);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    // const fetchData = () => {
    //     fetch('http://localhost:5000/display')
    //         .then(response => response.json())
    //         .then(data => {
    //             if (Array.isArray(data)) {
    //                 setImageData(data);
    //                 console.log("data from backend",data)
    //             } else {
    //                 console.error('Data is not an array:', data);
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // };

    const handleImageToggle = (rowIndex, imageIndex) => {
        setVisibleImage(prevVisibleImage =>
            prevVisibleImage.index === rowIndex && prevVisibleImage.imageIndex === imageIndex
                ? { index: null, imageIndex: null }  // Hide image if it's already visible
                : { index: rowIndex, imageIndex }    // Show new image
        );
    };
    const regenerateText = (url) => {
        if (capturedImages.length === 0) return;

        const formData = new FormData();
        capturedImages.forEach((dataURL, index) => {
            const blob = dataURItoBlob(dataURL);
            formData.append('images', blob, `image_${index}.jpg`);
        });

        fetch(`${API_URL}/generate`, {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            console.log("Response Data:", data);
            if (Array.isArray(data)) {
                const ocrTexts = data.map(item => item.ocr_text).join('\n');
                const s3Urls = data.map(item => item.s3_url);
                setOcrTexts(ocrTexts);
                setS3Urls(s3Urls);
                // Assuming you have a way to display these results, update the state or the DOM accordingly
                alert(`OCR Text: ${ocrTexts}\nS3 URLs: ${s3Urls.join('\n')}`);
                // Enable the GPT button if needed
            } else {
                console.error("Error: Expected an array but got:", data);
            }
        })
        .catch(error => console.error('Error:', error));
    };


    const gptResponseText = (url) => {
        console.log("ocr text",ocrTexts)
        console.log("gpt text",s3Urls)
        fetch(`${API_URL}/gpt_category_response`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                text: ocrTexts,
                s3_urls: s3Urls
            })
        })
        .then(response => response.json())
    .then(data => {
        console.log("gpt Response Data:", data);
        if (data.gpt_response) {
           console.log("data.gpt_response",data.gpt_response)
        } else {
            console.error('Error: GPT response is undefined', data);
            // gptResult.innerText = 'Error: Could not retrieve response';
        }
    })
    .catch(error => {
        console.error('Fetch error:', error);
        
    });

        // const formData = new FormData();
        // capturedImages.forEach((dataURL, index) => {
        //     const blob = dataURItoBlob(dataURL);
        //     formData.append('images', blob, `image_${index}.jpg`);
        // });

        // axios.post(url, formData)
        //     .then(response => {
        //         const data = response.data;
        //         console.log("Response Data:", data);
        //         if (Array.isArray(data)) {
        //             const extractedOcrTexts = data.map(item => item.ocr_text).join('\n');
        //             const extractedS3Urls = data.map(item => item.s3_url);
        //             setOcrTexts(extractedOcrTexts);
        //             setS3Urls(extractedS3Urls);
        //             alert(`OCR Text: ${extractedOcrTexts}\nS3 URLs: ${extractedS3Urls.join('\n')}`);
        //         } else {
        //             console.error("Error: Expected an array but got:", data);
        //         }
        //     })
        //     .catch(error => console.error('Error:', error));
    };


    // const regenerateText = (url) => {
    //     const selectedRows = imageData.filter((_, index) => selectedItems.includes(index));

    //     fetch(url, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(selectedRows)
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.message) {
    //                 alert(data.message);
    //                 fetchData();  // Refresh the table data
    //             } else if (data.error) {
    //                 alert(data.error);
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // };

    // const handleCheckboxChange = (index) => {
    //     setSelectedItems(prev => {
    //         if (prev.includes(index)) {
    //             return prev.filter(i => i !== index);
    //         } else {
    //             return [...prev, index];
    //         }
    //     });
    // };

    const buttonStyle = {
        marginRight: '10px', // Add spacing between buttons
        padding: '8px 16px'
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box sx={{display:'flex',alignItems:"flex-start",width:'100%',paddingLeft:'4%'}}>
            <IconButton color="primary" onClick={() => navigate('/links')}>
                <HomeIcon />
            </IconButton>
            </Box>
             
            <Typography sx={{fontFamily:'Montserrat',fontSize:'48px',fontWeight:700}} gutterBottom>Capture and Display Image</Typography>

            {!isVideoActive ? (
                <Button onClick={() => setIsVideoActive(true)} variant="contained" color="primary" sx={{ mx: 1 }}>
                    Start Video
                </Button>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                    <Box display="flex" justifyContent="center" mb={3}>
                        <video ref={videoRef} autoPlay muted style={{ width: '100%', maxWidth: '500px' }}></video>
                    </Box>
                    <Box display="flex" justifyContent="center" mb={3}>
                        <Button onClick={captureImage} variant="contained" color="primary" sx={{ mx: 1 }}>Capture Image</Button>
                        <Button  onClick={() => regenerateText(`${API_URL}/regenerate_ocr_text`)} variant="contained" color="secondary" sx={{ mx: 1 }}>Generate OCR</Button>
                        <Button onClick={() => gptResponseText(`${API_URL}/regenerate_gpt_text`)} variant="contained" color="secondary" sx={{ mx: 1 }}>Generate GPT</Button>
                        <Button onClick={() => setIsVideoActive(false)} variant="contained" color="error" sx={{ mx: 1 }}>Stop Video</Button>
                    </Box>
                </Box>
            )}

            <Box ref={capturedImagesRef} display="flex" flexDirection="column" alignItems="center"></Box>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

            <Button onClick={saveImages} variant="contained" sx={{ mt: 2 }}>Finish</Button>

            <Typography sx={{fontFamily:'Montserrat',fontSize:'28px',fontWeight:600}} gutterBottom mt={4}>Stored Table Details</Typography>

            <TableContainer component={Paper} sx={{paddingLeft:'4%',paddingRight:'4%',width:'92%'}}>
                <Table sx={{border:'1px solid black'}}>
                    <TableHead sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600}}>
                        <TableRow sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600, background:'grey'}}>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',color:'white',fontWeight:600}}>Select</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>Id</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>Image</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>Created</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>OCR Text</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>GPT Text</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>Service</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>Service Category</TableCell>
                            <TableCell sx={{fontFamily:'Montserrat',fontSize:'18px',fontWeight:600,color:'white'}}>Skills</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imageData.map((item, index) => (
                            <TableRow key={index}   sx={{
                                '&:nth-of-type(odd)': {
                                    backgroundColor: '#F3F3F3',
                                },
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },

                            }}>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedItems.includes(index)}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                </TableCell>
                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>{item[7]}</TableCell> {/* Assuming no ID is available, using index */}
                                <TableCell>
                                    <Box display="flex" flexDirection="column">
                                        {item[5] ? item[5].split(',').map((location, i) => (
                                            <Box key={i} display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
                                                <Button
                                                variant='outlined'
                                                    onClick={() => handleImageToggle(index, i)}
                                                    // color="secondary"
                                                    sx={{fontFamily:'Montserrat',fontSize:'12px',minWidth:'120px',
                                                        padding:'4px',fontWeight:400,background:'0063cc',
                                                    '&:hover':{fontFamily:'Montserrat',fontSize:'12px',background:'0063cc',
                                                        padding:'4px',fontWeight:400}}}
                                                >
                                                    Show Image {i + 1}
                                                </Button>
                                                {visibleImage.index === index && visibleImage.imageIndex === i && (
                                                    <Box mt={1} ml={2}> {/* Margin added above the image */}
                                                        <img
                                                            src={location.trim()}
                                                            alt={`Image ${i + 1}`}
                                                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        )) : "No Image Available"}
                                    </Box>

                                </TableCell>

                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>{item[6]}</TableCell> {/* created_at */}
                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>
                                    <TruncatedText sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}} text={item[3]} boxWidth={200}/>
                                </TableCell> {/* ocr_text */}
                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>
                                <TruncatedText text={item[4]} boxWidth={200}/>
                                    </TableCell> {/* service */}
                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>
                                <TruncatedText text={item[0]} boxWidth={200}/>
                                    </TableCell> {/* service */}
                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>
                                <TruncatedText text={item[1]} boxWidth={200}/>
                                    </TableCell> {/* service */}
                                <TableCell sx={{fontFamily:'Montserrat',fontSize:'14px',fontWeight:400}}>
                                <TruncatedText text={item[2]} boxWidth={200}/>
                                    </TableCell> {/* skills */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

};

// Helper function to convert data URL to Blob
const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
};

export default CardScanner;
