import React, { useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography } from '@mui/material';

const ManageSp = () => {
  const [serviceProviderData, setServiceProviderData] = useState('');
  const [response, setResponse] = useState(null);
  const [queryText, setQueryText] = useState('');
  const [searchResults, setSearchResults] = useState({ results: [] });

  const formatText = (text) => {
    return text.replace(/\n/g, ' ');
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(
        'http://localhost:5000/sp_data_embedding',
        { serviceProviderData },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setResponse(res.data);
    } catch (error) {
      console.error("There was an error!", error);
      setResponse({ error: "There was an error!" });
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(
        'http://localhost:5000/search_service_providers',
        { queryText },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setSearchResults(res.data);
    } catch (error) {
      console.error("There was an error!", error);
      setSearchResults({ error: "There was an error!" });
    }
  };

  return (
    <Box className="App" sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Add Service Provider
      </Typography>
      <form onSubmit={handleSave}>
        <TextField
          fullWidth
          multiline
          margin="normal"
          variant="outlined"
          label="Enter service provider details"
          value={serviceProviderData}
          onChange={(e) => setServiceProviderData(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </form>
      {response && (
        <Box mt={4}>
          <Typography variant="h5">Response</Typography>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </Box>
      )}

      <Box mt={6}>
        <Typography variant="h4" gutterBottom>
          Search Service Providers
        </Typography>
        <form onSubmit={handleSearch}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Enter search query"
            value={queryText}
            onChange={(e) => setQueryText(e.target.value)}
          />
          <Button type="submit" variant="contained" color="secondary">
            Search
          </Button>
        </form>
        <Box mt={4}>
          <Typography variant="h5">Search Results</Typography>
          {Array.isArray(searchResults.results) && searchResults.results.length > 0 ? (
  searchResults.results.map((result, index) => (
    result.score > 0.77 ? (
      <Box key={index} mt={2}>
        <Typography variant="h6">Service Provider Details:</Typography>
        <Typography variant="body1">Leboco Code: {result.leboco_code}</Typography>
        {/* <Typography variant="body1">Score: {result.score}</Typography> */}
      </Box>
    ) : (
      <Typography key={index} variant="body1">No service providers available with a score greater than 0.80.</Typography>
    )
  ))
) : (
  <Typography variant="body1">No service providers available.</Typography>
)}

        </Box>
      </Box>
    </Box>
  );
};

export default ManageSp;
