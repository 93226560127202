
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Pages/Home';
import Service from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';



function App() {
  return (
    <div style={{ height: '100%' }}>
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/home" element={<Home/>} />
      <Route path="/services" element={<Service/>} />
      <Route path="/about-us" element={<AboutUs/>} />
      <Route path="/contact-us" element={<ContactUs/>} />
     
      </Routes>
    </BrowserRouter>
  </div>
);
}

export default App;
