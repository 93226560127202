// src/ServiceProviderSearch.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography } from '@mui/material';

const ServiceProviderSearch = () => {
  const [requirement, setRequirement] = useState('');
  const [response, setResponse] = useState(null);
  const [searchTags, setSearchTags] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post('http://localhost:5000/check_requirement', { requirement }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setResponse(res.data);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    // Fetch search tags from the backend or define them statically
    const fetchSearchTags = async () => {
      try {
        const res = await axios.get('http://localhost:5000/search_tags');
        setSearchTags(res.data);
      } catch (error) {
        console.error("There was an error fetching search tags!", error);
      }
    };

    fetchSearchTags();
  }, []);

  return (
    <Box className="App" sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Service Provider Search
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Enter your requirement"
          value={requirement}
          onChange={(e) => setRequirement(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
      {response && (
        <Box mt={4}>
          <Typography variant="h5">Response</Typography>
          <pre>{JSON.stringify(response, null, 2)}</pre>
          {response.suggested_search_tag && (
            <Typography>Suggested Tag: {response.suggested_search_tag}</Typography>
          )}
          {response.service_providers && response.service_providers.length > 0 && (
            <Box mt={4}>
              <Typography variant="h6">Suggested Providers:</Typography>
              <ul>
                {response.service_providers.map((provider, index) => (
                  <li key={index}>{provider.search_tag_type_value}</li> // Adjust this based on actual field names
                ))}
              </ul>
            </Box>
          )}
        </Box>
      )}
      {searchTags.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Search Tags</Typography>
          <ul>
            {searchTags.map((tag, index) => (
              <li key={index}>{tag}</li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default ServiceProviderSearch;
