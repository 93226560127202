import React, { useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography } from '@mui/material';

const AddEmbedding = () => {
  const [embeddingData, setEmbeddingData] = useState('');
  const [response, setResponse] = useState(null);
  const [queryText, setQueryText] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(
        'http://localhost:5000/add_embedding',
        { embeddingData },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setResponse(res.data);
    } catch (error) {
      console.error("There was an error!", error);
      setResponse({ error: "There was an error!" });
    }
  };
  const handleSearch = async (event) => {
    event.preventDefault();
    console.log(queryText)
    try {
      const res = await axios.post(
        'http://localhost:5000/search_embeddings',
        { queryText },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setSearchResults(res.data);
    } catch (error) {
      console.error("There was an error!", error);
      setSearchResults({ error: "There was an error!" });
    }
  };

  return (
    <Box className="App" sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Add Embedding Data
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Enter embedding data"
          value={embeddingData}
          onChange={(e) => setEmbeddingData(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
      {response && (
        <Box mt={4}>
          <Typography variant="h5">Response</Typography>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </Box>
      )}

<Box mt={6}>
        <Typography variant="h4" gutterBottom>
          Search Related Terms
        </Typography>
        <form onSubmit={handleSearch}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Enter search query"
            value={queryText}
          onChange={(e) => setQueryText(e.target.value)}
          />
          <Button type="submit" variant="contained" color="secondary">
            Search
          </Button>
        </form>
        {searchResults && (
          <Box mt={4}>
            <Typography variant="h5">Search Results</Typography>
            <pre>{JSON.stringify(searchResults, null, 2)}</pre>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddEmbedding;
