// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ServiceProviderSearch from './pages/ServiceProviderSearch';
import AddEmbedding from './pages/AddEmbedding';
import ManageSp from './pages/ManageSp';
import ViewSp from './pages/ViewSp';
import Query from './pages/Query';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import Links from './pages/Links';
import CardScanner from './pages/CardScanner';
import CardProcessor from './pages/CardProcessor';
import LawnSignScanner from './pages/LawnSignScanner';
import LawnSignReport from './pages/LawnSignReport';
import LawnSignImageScanner from './pages/LawnSignImageScanner';
import LawnSignImageData from './pages/LawnSignImageData';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/sp-search" element={<PrivateRoute><ServiceProviderSearch /></PrivateRoute>} />
        {/* add embedding data,search related terms */}
        <Route path="/add-embedding" element={<PrivateRoute><AddEmbedding /></PrivateRoute>} />
        {/* add embedding json data,search service provider */}
        <Route path="/manage-sp" element={<PrivateRoute><ManageSp /></PrivateRoute>} />
        {/* show all the service providers */}
        <Route path="/view-sp" element={<PrivateRoute><ViewSp /></PrivateRoute>} />
        {/* show query and city */}
        <Route path="/query" element={<PrivateRoute><Query /></PrivateRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/links" element={<PrivateRoute><Links /></PrivateRoute>} />
        <Route path="/CardScanner" element={<PrivateRoute><CardScanner /></PrivateRoute>} />
        <Route path="/CardProcessor" element={<PrivateRoute><CardProcessor /></PrivateRoute>} />
        <Route path="/LawnSignScanner" element={<PrivateRoute>< LawnSignScanner/></PrivateRoute>}/>
        <Route path="/LawnSignReport" element={<PrivateRoute><LawnSignReport /></PrivateRoute>}/>
        <Route path="/LawnSignImageScanner" element={<PrivateRoute>< LawnSignImageScanner/></PrivateRoute>}/>
        <Route path="/LawnSignImageData" element={<PrivateRoute>< LawnSignImageData/></PrivateRoute>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
