


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid2, Typography } from "@mui/material";
import { useEffect } from "react";
import '../App.css';
import { Link } from 'react-router-dom';
import CopyrightIcon from '@mui/icons-material/Copyright';
import Colors from '../Components/Colors';
import footer from '../Images/footer.png'
import footerMob from '../Images/footerMob.png'
import TypographyComponent from './TypographyComponent';


function Footer(props) {
  console.log(props)

  const {currentPageFooter} = props   
  
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      
      const menus = [
        { label: "PPC", link: `/services?focus=PPC` }, 
        { label: 'SEO', link: `/services?focus=SEO`},          
        { label: 'Social media marketing', link: `/services?focus=Social media marketing` },
        { label: 'Content creation', link: `/services?focus=Content creation` } , 
        { label: 'Email marketing', link: `/services?focus=Email marketing` } , 
        { label: 'UI-UX designing', link:`/services?focus=UI-UX designing` } , 
        { label: 'UI-UX audit', link: `/services?focus=UI-UX audit`} , 
       
    ];
   
    const menuColumn2 = [
      { label: "Home", link: "/" }, 
      { label: "Services", link: "/services" },          
      { label: "About us", link: "/about-us" },
      { label: "Contact us", link: "/contact-us" } , 
     
  ];

   

    const menuColumn4 = [            
        { label: "LinkedIn", link: "https://www.linkedin.com/in/helping-flock-102416332/" },
        { label: "X", link: "https://x.com/helpingflocks/" },
        
    ];
    
    

   
    return (
      <Grid2 sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px'}}>
         <Box sx={{
                width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' }, 
                flexDirection: 'row',
            }}>
       <Box  sx={{
    position: 'relative',  display: 'flex', flexDirection: 'column', width: '100%', backgroundImage: `url(${footer})`,
    backgroundRepeat: 'no-repeat',  backgroundSize: '100% 100%', height: '96%', justifyContent: 'center',
    alignItems: 'center', paddingTop: '2%', paddingBottom: '2%',
    '&::before': { content: '""',  position: 'absolute', top: 0,left: 0, width: '100%',
    height: '100%', backgroundColor: '#132436DE',  },}}>

           <Box sx={{display: 'flex', flexDirection:'row', width: '100%', height: '100%',justifyContent: 'center',
             alignItems: 'center' ,}}>

                <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
                     gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%'}}>

                    <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
                          gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%', justifyContent: 'center',alignItems: 'flex-start' ,}}>

                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'4px', sm:'4px', xs:'0px' },padding:0,  
                                  justifyContent: 'center',alignItems: 'flex-start' ,width:'calc(100%/3)', paddingLeft:'10%'}}>
                             <TypographyComponent sx={{fontSize:{xs:'12px', md:'18px'}, color: Colors.white, paddingBottom:'4px',paddingLeft:'4px', zIndex:9}}>Our services</TypographyComponent>
                               {menus.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white, textTransform: "none", fontFamily: "SF Pro Display", fontSize: "12px",
                                                  lineHeight: "20px", fontWeight:  400,
                                                  textAlign:'left', minWidth: '0px !important',
                                                  '& .css-iuwf2y-MuiButtonBase-root-MuiButton-root': {
                                                        minWidth: '0px !important', 
                                                    },
                                                  '&:hover': { textDecoration: 'underline', textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,},
                                                                                 
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>

                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'4px', sm:'4px', xs:'0px' }, 
                                  justifyContent: 'center',alignItems: 'flex-start' ,width:'calc(100%/3)' ,paddingLeft:'10%' }}>
                            {menuColumn2.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "SF Pro Display", fontSize: "12px", minWidth: 'auto',
                                                  lineHeight: "20px",   fontWeight: 400,textDecoration: currentPageFooter === menu.label ? 'underline': 'none',
                                                  textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,
                                                  '&:hover': { textDecoration: 'underline', textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,},                                  
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box> 
                          
                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'4px', sm:'4px', xs:'0px' }, 
                                  justifyContent: 'flex-start', alignItems: 'flex-start', width:'calc(100%/3)' ,paddingLeft:'10%' ,}}>
                            {menuColumn4.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "SF Pro Display", fontSize: "12px",minWidth: 'auto',
                                                  lineHeight: "20px",fontWeight: "400", '&:hover': { textDecoration: 'underline', textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,},                                    
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>                
                    </Box>
                </Box>
           
               </Box>

            <Box sx={{display:'flex',flexDirection:'row',gap:'5px', justifyContent: 'center', alignItems: 'center'
                     ,width:'100%',paddingTop:'20px', zIndex:9}}>
                <CopyrightIcon sx={{color:Colors.white,fontSize: '9px',lineHeight: '18px', }}></CopyrightIcon>
                <Typography sx={{fontFamily: "SF Pro Display", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',
                  color:Colors.white,}}>2023-2025</Typography>
                <Typography sx={{fontFamily: "SF Pro Display", fontSize: '8px',  lineHeight: '18px', fontWeight: '400', 
                 color:Colors.white, }}>all rights reserved by</Typography>
               <Link to="https://www.varsha-info.com/"   target="_blank"  rel="noopener noreferrer" 
                  style={{ textDecoration: 'none', color: Colors.white, fontFamily: "SF Pro Display", fontSize: '8px',  
                    lineHeight: '18px', fontWeight: '400', transition: 'text-decoration 0.2s',  textUnderlineOffset: "5px",}}
                  onMouseEnter={(e) => {e.target.style.textDecoration = 'underline'; }}
                  onMouseLeave={(e) => {e.target.style.textDecoration = 'none'; }}>
                  www.varsha-info.com
              </Link>







            </Box>

        </Box> 
        </Box>

        <Box sx={{
                width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display: { xs: 'flex', md: 'none' }, 
                flexDirection: 'row',
            }}>
        <Box  sx={{display:'flex',flexDirection:'column',width:'100%',backgroundImage: `url(${footerMob})`, 
             backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', height: '94%', justifyContent: 'center',
             alignItems: 'center',paddingTop:'3%', paddingBottom:'3%', }}>

           <Box sx={{display: 'flex', flexDirection:'row', width: '100%', height: '100%',justifyContent: 'center',
             alignItems: 'center' ,}}>

                <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:{ md:'row', sm:'row', xs:'column' }, 
                     gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%'}}>

                    <Box sx={{display: { md: 'flex', sm: 'flex', xs:'flex' },flexDirection:'row', 
                          gap:{ md:'12%', sm:'12%', xs:'10%' }, width:'100%', height: '100%',}}>

                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' },padding:0,  
                                  justifyContent: 'center',alignItems: 'flex-start' ,width:'calc(100%/3)', paddingLeft:'10%'}}>
                                     <TypographyComponent sx={{fontSize:{xs:'12px', md:'18px'}, color: Colors.white, paddingBottom:'4px',paddingLeft:'8px'}}>Our services</TypographyComponent>
                                {menus.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white, textTransform: "none", fontFamily: "SF Pro Display", fontSize: "10px",
                                                  lineHeight: "16px", fontWeight: 400,
                                                  textAlign:'left', minWidth: 'fit-content !important',                                                 
                                                 '&:hover': { textDecoration: 'underline', textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,}
                                                                                 
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                                      
                            </Box>
                          
                            <Box  sx={{display:'flex',flexDirection:'column',gap:{ md:'8px', sm:'8px', xs:'0px' }, 
                                  justifyContent: 'flex-start', alignItems: 'flex-start', width:'calc(100%/3)' ,paddingLeft:'10%' ,}}>
                                    {menuColumn2.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                              to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "SF Pro Display", fontSize: "10px", 
                                                  lineHeight: "16px", fontWeight:  400, minWidth: 'auto',textDecoration: currentPageFooter === menu.label ? 'underline': 'none',
                                                  textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,
                                                  '&:hover': { textDecoration: 'underline', textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,}                               
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            {menuColumn4.map((menu) => (
                                          <Button
                                              key={menu.label}
                                              component={Link}
                                             to={menu.link}
                                              sx={{
                                                  color:Colors.white,textTransform: "none", fontFamily: "SF Pro Display", fontSize: "12px",
                                                  lineHeight: "16px",fontWeight:  400, '&:hover': { textDecoration: 'underline', textUnderlineOffset:'7px',background:'none',textDecorationColor: Colors.Red,}                                    
                                              }}>
                                              {menu.label}
                                          </Button>
                                      ))}
                            </Box>                
                    </Box>
                </Box>
           
               </Box>

            <Box sx={{display:'flex',flexDirection:'row',gap:'5px', justifyContent: 'center', alignItems: 'center'
                     ,width:'100%',paddingTop:'20px'}}>
                <CopyrightIcon sx={{color:Colors.white,fontSize: '9px',lineHeight: '18px', }}></CopyrightIcon>
                <Typography sx={{fontFamily: "SF Pro Display", fontSize: '8px',  lineHeight: '18px', fontWeight: '400',
                  color:Colors.white,}}>2023-2025</Typography>
                <Typography sx={{fontFamily: "SF Pro Display", fontSize: '8px',  lineHeight: '18px', fontWeight: '400', 
                 color:Colors.white, }}>all rights reserved by</Typography>
               <Link to="https://www.varsha-info.com/"   target="_blank"  rel="noopener noreferrer" 
                  style={{ textDecoration: 'none', color: Colors.white, fontFamily: "SF Pro Display", fontSize: '8px',  
                    lineHeight: '18px', fontWeight: '400', transition: 'text-decoration 0.2s',  textUnderlineOffset: "5px",}}
                  onMouseEnter={(e) => {e.target.style.textDecoration = 'underline'; }}
                  onMouseLeave={(e) => {e.target.style.textDecoration = 'none'; }}>
                  www.varsha-info.com
              </Link>







            </Box>

        </Box> 
        </Box>
      </Grid2>
          );
    }export default Footer;