import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, Select, MenuItem, Button, Box, Grid, Typography, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { API_URL } from '../components/Constants';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../components/Auth';

const ViewSp = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
const city= [
   "Windsor",
  "LaSalle",
  "Essex",
  "Kingsville",
  "Chatham",
  "Chatham-Kent",
  "London",
  "St. Williams",
  "Strathroy",
  "Sarnia",
  "Woodstock",
  "Brantford",
  "Hamilton",
  "Burlington",
  "Oakville",
  "Mississauga",
  "Brampton",
  "Georgetown",
  "Milton",
  "Vaughan",
  "Concord",
  "Richmond Hill",
  "Markham",
  "Scarborough",
  "Toronto",
  "North York",
  "York",
  "Thornhill",
  "Newmarket",
  "Whitchurch-Stouffville",
  "Aurora",
  "Bradford",
  "Barrie",
  "Georgina",
  "Orillia",
  "Muskoka",
  "Bracebridge",
  "Huntsville",
  "Collingwood",
  "Orangeville",
  "Fergus",
  "Guelph",
  "Kitchener",
  "Cambridge",
  "Waterloo",
  "Bolton",
  "Caledon",
  "Ajax",
  "Pickering",
  "Oshawa",
  "Whitby",
  "Bowmanville",
  "Cobourg",
  "Belleville",
  "Kingston",
  "Brockville",
  "Cornwall",
  "Ottawa",
  "Kanata",
  "Perth",
  "Haliburton",
  "Grimsby",
  "Niagara Falls",
  "Port Hope",
  "Queensville",
  "St. Catharines",
  "Thunder Bay",
  "Timmins",
  "North Bay",
  "Owen Sound",
  "Could Bay",
  "Springfield",
  "Lakefield",
  "Belleville", // This entry is duplicated
  "Peterborough",
  "Sudbury",
  "Sault Ste. Marie"
  ];
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [categoryFilter, setCategoryFilter] = useState('');
    const [serviceFilter, setServiceFilter] = useState('');
    const [uniqueServices, setUniqueServices] = useState([]);
    const [uniqueCities, setUniqueCities] = useState([]);
    const [uniqueCategories, setUniqueCategories] = useState([]);
    const [cityFilter, setCityFilter] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const flattenCityFilter = (filter) => {
        return filter.flat();
    };



    const handleServiceFilterChange = (event) => {
        setServiceFilter(event.target.value);
        setCurrentPage(1)
    };

    const normalizeData = (item) => {
        return {
            full_name: item[1]?.full_name || item[1]?.['Full Name'] ||
                item[1]?.Full_Name || item[1]?.fullName || item[1]?.FullName || item[1]?.fullname || '',
            city: item[1]?.city || item[1]?.City || '',
            address: item[1]?.address || item[1]?.Address || '',
            phone_number: item[1]?.phone_number || item[1]?.phone || item[1]?.PhoneNumber || '',
            services: item[1]?.services || '',
            description: item[1]?.description || item[1]?.Description || '',
            leboco_code: item[1]?.leboco_code || '',
            category_name: item[1]?.category_name || item[1]?.category || '',
            skills: Array.isArray(item[1]?.skills) ? item[1]?.skills : [],
            types_of_services: Array.isArray(item[1]?.types_of_services) ? item[1]?.types_of_services : []
        };
    };


    const itemsPerPage = 50; // Number of rows per page

    // Fetch data from the backend when the component mounts or pagination changes
    useEffect(() => {
        const fetchData = async () => {
            console.log("city", cityFilter)
            try {
                const response = await axios.get(`${API_URL}/api/get-all-service-providers`, {
                    params: {
                        page: currentPage,
                        page_size: itemsPerPage,
                        search,
                        category: categoryFilter,
                        service: serviceFilter,
                        city: cityFilter
                    }
                });
                console.log("response.data.data", response.data.data);
                setData(response.data.data || []);
                setTotalPages(response.data.total_pages);

                // const servicesResponse = await axios.get(`${API_URL}/api/get-unique-services`);
                // setUniqueServices(servicesResponse.data.data);

                // Fetch unique categories
                // const categoriesResponse = await axios.get(`${API_URL}/api/get-unique-categories`);
                // setUniqueCategories(categoriesResponse.data);

                // Fetch unique cities
                // const citiesResponse = await axios.get(`${API_URL}/api/get-unique-cities`);
                // setCities(citiesResponse.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage, search, categoryFilter, serviceFilter, cityFilter]);

    const fetchServices = async () => {
        setLoading(true);
        try {
            const servicesResponse = await axios.get(`${API_URL}/api/get-unique-services`);
            setUniqueServices(servicesResponse.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCategory = async () => {
        setLoading(true);
        try {
            const servicesResponse = await axios.get(`${API_URL}/api/get-unique-categories`);
            setUniqueCategories(servicesResponse.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCity = async () => {
        setLoading(true);
        try {
            const servicesResponse = await axios.get(`${API_URL}/api/get-unique-cities`);
          console.log(servicesResponse.data.data)
          const cities=(servicesResponse.data.data).flat();
          console.log(cities)
           //here we are using constant array now
          console.log(city)
          setUniqueCities(city);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    // const sanitizedData = (data || []).filter(item => 
    //     item.full_name !== undefined && 
    //     item.city !== undefined &&
    //     item.services !== undefined && 
    //     item.category_name !== undefined
    // );
    // const filteredData = data
    //     .map(item => normalizeData(item))
    //     .filter(item =>
    //         (item.full_name.toLowerCase().includes(search.toLowerCase()) ||
    //             item.city.toLowerCase().includes(search.toLowerCase())) &&
    //             (categoryFilter === '' || item.category_name.includes(categoryFilter)) &&
    //         (serviceFilter === '' || item.services.includes(serviceFilter)) &&
    //         (cityFilter === '' || item.city.includes(cityFilter)) 
    //     );

    // const handleCityFilterChange = (event) => {
    //     setCityFilter(event.target.value);
    //     setCurrentPage(1);
    // };
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        setCurrentPage(1);
    };

    const handleCategoryFilterChange = (event) => {
        setCategoryFilter(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleCitySelect = (city) => {
        console.log("city filter",cityFilter,city)
        setCityFilter((prevCityFilter) => {
          if (prevCityFilter.includes(city)) {
            // Remove city if already selected
            return prevCityFilter.filter((selectedCity) => selectedCity !== city);
          } else {
            // Add city to selection
            return [...prevCityFilter, city];
          }
        });
      };
      
    const hasNextPage = currentPage < totalPages;
    const hasPreviousPage = currentPage > 1;
    const handleServiceDropdownOpen = () => {
        if (uniqueServices.length === 0) { // Only fetch if services haven't been loaded yet
            fetchServices();
        }
    };
    const handleCategoryDropdownOpen = () => {
        if (uniqueCategories.length === 0) {
            fetchCategory();
        }
    };
 

    const handleCityFilterChange = (event) => {
        console.log(event.target.value)
        setCityFilter(flattenCityFilter(event.target.value));
        setCurrentPage(1);
        
    };
    const filteredData = data.map(item => normalizeData(item));
    const [selectedItems, setSelectedItems] = useState([]);
    const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
    const handleChange = (event) => {
        setSelectedItems(event.target.value);
      };
    return (
        <Container>
            <Box sx={{ display: 'flex', alignItems: "flex-start", width: '100%' }}>
                <IconButton color="primary" onClick={() => navigate('/links')}>
                    <HomeIcon />
                </IconButton>
            </Box>
            <Box display="flex" justifyContent="space-between" marginBottom="10px">

                <Button
                    component={Link}
                    to="/query" // Replace with the actual path
                    variant="contained"
                    color="primary"
                >
                    query Page
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        removeToken(); // Clear the token from localStorage
                        navigate('/login'); // Redirect to the login page
                    }}
                >
                    Sign Out
                </Button>
            </Box>
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>

                <Grid item xs={2}>
                    <Typography>
                        search here
                    </Typography>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={search}
                        onChange={handleSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel id="category-filter-label">Filter by Category</InputLabel>
                    <Select
                        labelId="category-filter-label"
                        value={categoryFilter}
                        onChange={handleCategoryFilterChange}
                        onOpen={handleCategoryDropdownOpen}
                        displayEmpty
                        renderValue={(selected) => (selected ? selected : 'All Category')}
                    >
                        <MenuItem value="">All Category</MenuItem>
                        {loading ? (
                            <MenuItem disabled>
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            uniqueCategories.map((category_name, index) => (
                                <MenuItem key={index} value={category_name}>
                                    {category_name}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel id="service-filter-label">Filter by Service</InputLabel>

                    <Select
                        labelId="service-filter-label"
                        value={serviceFilter}
                        onChange={handleServiceFilterChange}
                        onOpen={handleServiceDropdownOpen}
                        displayEmpty
                        renderValue={(selected) => (selected ? selected : 'All Service')}
                    >
                        <MenuItem value="">All Service</MenuItem>
                        {loading ? (
                            <MenuItem disabled>
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            uniqueServices.map((service, index) => (
                                <MenuItem key={index} value={service}>
                                    {service}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel id="city-filter-label">Filter by city</InputLabel>
                    <Select
                        labelId="city-filter-label"
                        value={cityFilter}
                        onChange={handleCityFilterChange}
                        onOpen={fetchCity}
                        multiple
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return 'All Cities';
                            }
                            return selected.join(', ');
                        }}
                    >
                        <MenuItem value="">All Cities</MenuItem>
                        {loading ? (
                            <MenuItem disabled>
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            uniqueCities.map((city, index) => (
                                <MenuItem
                                key={index}
                                value={city}                                                           >
                                {city}
                              </MenuItem>                        
                            ))
                        )}
                    </Select>
                </Grid>
              
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Full Name</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Services</TableCell>
                            {/* <TableCell>Description</TableCell> */}
                            <TableCell>Leboco Code</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Skills</TableCell>
                            <TableCell>Types of Services</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.full_name}</TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{row.address}</TableCell>
                                <TableCell>{row.phone_number}</TableCell>
                                <TableCell>{row.services}</TableCell>
                                {/* <TableCell>{row.description}</TableCell> */}
                                <TableCell>{row.leboco_code}</TableCell>
                                <TableCell>{row.category_name}</TableCell>
                                <TableCell>{Array.isArray(row.skills) ? row.skills.join(', ') : row.skills}</TableCell>
                                <TableCell>{Array.isArray(row.types_of_services) ? row.types_of_services.join(', ') : row.types_of_services}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ marginTop: '20px' ,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Box sx={{display:'flex',gap:'10px'}}>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={!hasPreviousPage}
                >
                    Previous Page
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={!hasNextPage}
                >
                    Next Page
                </Button>
                </Box>
               
              
                <Typography variant="body2">
                    Page {currentPage} of {totalPages}
                </Typography>

            </Box>
        </Container>
    );
};

export default ViewSp;
