import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Box,
  Container,
} from '@mui/material';
import { API_URL } from '../components/Constants';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const LawnSignImageData = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchData = () => {
    fetch(`${API_URL}/display_lawnsignImage`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setData(data);
        } else {
          console.error('Data is not an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const regenerateText = (url) => {
    const selectedRows = data.filter(row => row.selected);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedRows.map(row => ({
        content: row[7], // Assuming URL is in the second cell
      }))),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          alert(data.message);
          fetchData(); // Refresh the table data
        } else if (data.error) {
          alert(data.error);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckboxChange = (index) => {
    const updatedData = [...data];
    updatedData[index].selected = !updatedData[index].selected;
    setData(updatedData);
  };

  const handleCellClick = (index, cellIndex) => {
    const updatedData = [...data];
    updatedData[index][`showDetails${cellIndex}`] = !updatedData[index][`showDetails${cellIndex}`];
    setData(updatedData);
  };

  const createCell = (content, index, cellIndex, isLocation = false, previewLength = 50) => {
    const cellContent = content || '';
    const previewText = cellContent.length > previewLength ? `${cellContent.slice(0, previewLength)}...` : cellContent;

    return (
      <TableCell key={cellIndex} className="cell-content" onClick={() => handleCellClick(index, cellIndex)}>
        <Typography variant="body2" noWrap>{previewText}</Typography>
        {data[index][`showDetails${cellIndex}`] && (
          <div className="details">
            {isLocation ? (
              cellContent.split(',').map((location, i) => (
                <img key={i} src={location.trim()} alt={`Preview ${i}`} style={{ maxWidth: '100%' }} />
              ))
            ) : (
              <Typography variant="body2">{cellContent}</Typography>
            )}
          </div>
        )}
      </TableCell>
    );
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: "flex-start", width: 'calc(100% - 4%)', paddingLeft: '4%' }}>
        <IconButton color="primary" onClick={() => navigate('/links')}>
          <HomeIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: "center", flexDirection:'column',width: 'calc(100% - 4%)', paddingLeft: '4%' }}>
        <Typography variant="h4" gutterBottom>Stored Table Details</Typography>
        <Box className="buttons" display="flex" flexDirection="row" gap="4px">
          <Button variant="contained" color="primary" onClick={() => regenerateText(`${API_URL}/regenerate_ocr_text_lawnsignData`)}>Refresh OCR Text</Button>
          <Button variant="contained" color="secondary" onClick={() => regenerateText(`${API_URL}/regenerate_gpt_text_lawnsignData`)}>GPT Text Refresh</Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: "center", width: 'calc(100% - 4%)', paddingLeft: '4%', paddingRight: '4%' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>OCR Text</TableCell>
              <TableCell>GPT Text</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Service Category</TableCell>
              <TableCell>Skills</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={item.selected || false}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                {createCell(item[7], index, 1)}
                {createCell(item[5], index, 2, true)} {/* Image (location) */}
                {createCell(item[6], index, 3)}
                {createCell(item[3], index, 4)}
                {createCell(item[4], index, 5)}
                {createCell(item[0], index, 6)}
                {createCell(item[1], index, 7)}
                {createCell(item[2], index, 8)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </Box>
     
    </Container>
  );
};

export default LawnSignImageData;
