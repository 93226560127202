export const getToken = () => {
    return localStorage.getItem('token'); // Retrieve token from localStorage
  };
  
  export const setToken = (token) => {
    localStorage.setItem('token', token); // Save token to localStorage
  };
  
  export const removeToken = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
  };
  