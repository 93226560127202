import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Container, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Box, TablePagination
} from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { removeToken } from '../components/Auth';
import { API_URL } from '../components/Constants';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';


function Query() {
  const navigate = useNavigate(); // Initialize navigate

  const [data, setData] = useState([]);
  const [lebocoCodes, setLebocoCodes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50); // Items per page
  const [totalRecords, setTotalRecords] = useState(0); // Total number of records

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-query-data`, {
          params: {
            page: page + 1, // Adjusting for zero-based index
            limit: rowsPerPage
          }
        });
console.log(response.data.data);
        // Transform data to match table structure
        const transformedData = response.data.data.map((item) => ({
          query_id: item[0], // Assuming the first item is the query_id
          city: item[1]?.trim() || '', // City
          query: item[2]?.trim() || '', // Query
          leboco_codes: item.slice(3, 8),
          status: item[8]
        }));

        setData(transformedData);
        setLebocoCodes(transformedData.map(row => row.leboco_codes || ['', '', '', '', '']));
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  const handleCodeChange = (rowIndex, codeIndex, value) => {
    setLebocoCodes(prevCodes => {
      const newCodes = [...prevCodes];
      newCodes[rowIndex][codeIndex] = value;
      return newCodes;
    });
  };

  const updateRowData = (rowIndex, payload) => {
    // Assuming you have a state variable `data` which is an array of rows
    setData(prevData => {
      // Create a new array with updated data
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex], ...payload }; // Update the specific row with new data
      return newData;
    });
  };

  const handleSave = async (rowIndex) => {
    const payload = {
      query_id: data[rowIndex].query_id, // Include query_id in the payload
      city: data[rowIndex].city,
      query: data[rowIndex].query,
      leboco_codes: lebocoCodes[rowIndex], // This should be an array of 5 leboco codes
    };

    try {
      const response = await axios.post(`${API_URL}/api/save-leboco-codes`, payload);
      if (response.status === 200) {
        console.log('Data saved successfully for', data[rowIndex].city);
        // Optionally, update UI to reflect successful save
        updateRowData(rowIndex, payload);
      } else {
        console.error('Failed to save data for', data[rowIndex].city);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleIgnore = async (rowIndex) => {
    const payload = {
      query_id: data[rowIndex].query_id,
      ignored: 'ignored' // Set the ignored state to true
    };

    try {
      const response = await axios.post(`${API_URL}/api/update-ignored-state`, payload);
      if (response.status === 200) {
        console.log('Data ignored successfully for', data[rowIndex].city);
        updateRowData(rowIndex, { status: 'ignored' });
      } else {
        console.error('Failed to ignore data for', data[rowIndex].city);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 whenever rows per page change
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: "flex-start", width: '100%' }}>
        <IconButton color="primary" onClick={() => navigate('/links')}>
          <HomeIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" marginBottom="10px">
        <Button
          component={Link}
          to="/view-sp"
          variant="contained"
          color="primary"
        >
          Search Page
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            removeToken(); // Clear the token from localStorage
            navigate('/login'); // Redirect to the login page
          }}
        >
          Sign Out
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell style={{ width: '200px' }}>Query</TableCell>
              <TableCell>Leboco Code 1</TableCell>
              <TableCell>Leboco Code 2</TableCell>
              <TableCell>Leboco Code 3</TableCell>
              <TableCell>Leboco Code 4</TableCell>
              <TableCell>Leboco Code 5</TableCell>
              <TableCell>Save</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={row.query_id}>
                <TableCell>{row.city}</TableCell>
                <TableCell style={{ width: '200px' }}>{row.query}</TableCell>
                {lebocoCodes[rowIndex]?.map((code, codeIndex) => (
                  <TableCell key={codeIndex}>
                    <TextField
                      value={code || ''}
                      onChange={(e) => handleCodeChange(rowIndex, codeIndex, e.target.value)}
                    />
                  </TableCell>
                ))}

                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(rowIndex)}
                  >
                    Save
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleIgnore(rowIndex)}
                    disabled={row.status === 'ignored'}// Disable if already ignored
                  >
                    Ignore
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalRecords} // Total number of data entries
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
}

export default Query;
