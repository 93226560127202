
import React, { useState } from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemIcon, Grid2 } from '@mui/material';
import Header from '../Components/Header';
import headerbg from '../Images/headerbg.png'
import Colors from '../Components/Colors';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TypographyComponent from '../Components/TypographyComponent';
import { useMediaQuery } from '@mui/material';
import bgmob from '../Images/bgmob.jpg';

const AboutUs = () => {

    const location = useLocation();
    const isDesktop = useMediaQuery('(min-width:768px)');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const focusLabel = params.get('focus');
        if (focusLabel) {
            const element = document.getElementById(focusLabel);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <Grid2 container sx={{
            width: '100%', height: '100%', overflow: 'hidden',
            justifyContent: 'center', alignItems: 'center'
        }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center',
                alignItems: 'center', position: 'relative'
            }} >
                <Box sx={{
                    width: '100%', height: '410px', backgroundImage:  `url(${isDesktop ?headerbg:bgmob})`, backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', position: 'relative', zIndex: 1, justifyContent: 'center', display: 'flex',
                    alignItems: 'center', flexDirection: 'column',  gap: {sm:'54px',md:'54px',xs:'8px'}
                }} >
                    <Box sx={{
                        position: 'absolute', top: 0, left: 0, width: '100%',
                        height: '100%', backgroundColor: 'rgba(45, 45, 45, 0.48)'
                    }} />
                    <Header currentPage={'About us'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 2 }} />
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
                        alignItems: 'center'
                    }} >
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
                            alignItems: 'center', maxWidth: '90%', maxHeight: '90%', gap: '32px'
                        }} >

                            <TypographyComponent sx={{
                                fontWeight: 700,  fontSize: {sm:'48px',md:'48px',xs:'24px'}, color: '#ffffff', zIndex: 9,
                                width: '100%', lineHeight: {sm:'56px',md:'56px',xs:'28px'}, textAlign: 'center'
                            }}>
                                About Us</TypographyComponent>

                        </Box>

                    </Box>
                </Box>

            </Box>

            <Box sx={{
                height: '95%', display: 'flex', width: '100%', maxWidth: '90%', gap: '34px',
                paddingTop: {sm:'2%',md:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
            }}>
                <Box  sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
                    <TypographyComponent sx={{ fontWeight: 500, fontSize: {sm:'32px',md:'32px',xs:'14px'},
                     lineHeight:{sm:'36px',md:'36px',xs:'16px'} , color: Colors.black_80 }}>
                        About us
                    </TypographyComponent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TypographyComponent sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                            At Helping Flocks, we are dedicated to elevating your brand in the dynamic digital landscape. Founded with a vision to transform how businesses connect with their audience, our team of digital marketing experts combines creativity, data-driven strategies, and industry insights to deliver exceptional results. We understand the unique challenges that businesses face in today’s fast-paced market and are committed to providing tailored solutions that drive growth and success.

                        </TypographyComponent>
                        <TypographyComponent sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>

                            Our team is made up of passionate professionals with diverse expertise across all facets of digital marketing. From PPC and SEO to content creation and UI/UX design, we bring together a wealth of experience and skills to craft strategies that not only meet but exceed your expectations. We pride ourselves on our collaborative approach, working closely with you to understand your goals and develop personalized solutions that align with your vision.
                        </TypographyComponent>
                        <TypographyComponent sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                            At Helping Flocks, we believe in the power of innovation and staying ahead of the curve. Our commitment to continuous learning and adaptation ensures that we leverage the latest tools, trends, and technologies to keep your brand competitive. Whether you’re looking to enhance your online presence, engage with your audience, or drive conversions, we are here to provide you with cutting-edge solutions that deliver measurable results.
                        </TypographyComponent>
                        <TypographyComponent sx={{
                            fontWeight: 400,fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                            Our dedication to integrity and transparency sets us apart. We value open communication and work diligently to keep you informed about every aspect of your digital marketing journey. Our goal is to build lasting partnerships based on trust, reliability, and shared success. We measure our success by your achievements and are always striving to go above and beyond to help you reach new heights.
                        </TypographyComponent>
                        <TypographyComponent sx={{
                            fontWeight: 400,fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                            Join us at Helping Flocks and discover how our expertise can help your brand soar. Whether you’re a startup seeking a strong digital foundation or an established business aiming to amplify your presence, we are here to guide you every step of the way. Let’s work together to create impactful marketing strategies that resonate with your audience and drive your business forward.
                        </TypographyComponent>
                    </Box>
                </Box>
            </Box>
            <Footer currentPageFooter={'About us'}></Footer>
        </Grid2>

    );
};

export default AboutUs;
