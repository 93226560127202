// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/SFPRODISPLAYBLACKITALIC.OTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/SFPRODISPLAYBOLD.OTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/SFPRODISPLAYMEDIUM.OTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/SFPRODISPLAYREGULAR.OTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* src/index.css or src/App.css */
@font-face {
  font-family: 'SF Pro Display';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* Set default font for the body */
body {
  font-family: 'SF Pro Display', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA,iCAAiC;AACjC;EACE,6BAA6B;EAC7B,+DAAyE;EACzE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,+DAAkE;EAClE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,+DAAoE;EACpE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,+DAAqE;EACrE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,kCAAkC;AAClC;EACE,yCAAyC;AAC3C","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n/* src/index.css or src/App.css */\n@font-face {\n  font-family: 'SF Pro Display';\n  src: url('./assets/fonts/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');\n  font-weight: 900;\n  font-style: italic;\n}\n\n@font-face {\n  font-family: 'SF Pro Display';\n  src: url('./assets/fonts/SFPRODISPLAYBOLD.OTF') format('opentype');\n  font-weight: 600;\n  font-style: bold;\n}\n\n@font-face {\n  font-family: 'SF Pro Display';\n  src: url('./assets/fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');\n  font-weight: 500;\n  font-style: medium;\n}\n\n@font-face {\n  font-family: 'SF Pro Display';\n  src: url('./assets/fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');\n  font-weight: 400;\n  font-style: normal;\n}\n\n/* Set default font for the body */\nbody {\n  font-family: 'SF Pro Display', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
