import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; 
import logo from '../Images/logo.png'
import Colors from './Colors';
import { Avatar, TextField } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonRed from './ButtonRed';
import TypographyComponent from './TypographyComponent';
import TextfieldComponent from './TextFeildComponent';
import emailjs from 'emailjs-com';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';




const pages = ['Home', 'Services', 'About us', 'Contact us'];



function Header(props) {
    const {currentPage} = props

   

    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedPage, setSelectedPage] = useState('');    
    const [isGetInTouch, setIsGetInTouch] = useState(false);
    const [nickName, setNickName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isSuccessMessage, setIsSuccessMessage] = useState(false)
    const [nickNameError, setNickNameError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [phoneError, setPhoneError] = useState("");

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleMenuItemClick = (page) => {
        setSelectedPage(page);
    };

    const handleGetInTouchButtonClick = () => {
        setIsGetInTouch(true);
        setNickName('');
        setNickNameError('');
        setPhoneNo('');
        setPhoneError('');
        setMessage('');
        setMessageError('');
    };    
    
    const handleCloseGetInTouchPopUp = () => {
        setIsGetInTouch(false);
    };    
   

    const handleNickNameChange = (event) => {
        setNickName(event.target.value);
        setNickNameError('')
      };
    
      const handleLastNameChange = (event) => {
        setLastName(event.target.value);
      };
    
      const handlePhoneNoChange = (event) => {
        setPhoneNo(event.target.value);
        setPhoneError('')
      };
    
      const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
    
      const handleMessageChange = (event) => {
        setMessage(event.target.value);
        setMessageError('')
      };
    
   
      const handleSubmitButtonClick = async (event) => {
        event.preventDefault();
        setNickNameError('');
        setPhoneError('');
        setMessageError('');
        let isValid = true;
      
        if (!nickName) {
          setNickNameError('Nick name is required');
          isValid = false;
        }
        const phoneRegex = /^\d{10,12}$/;
        if (!phoneNo) {
          setPhoneError('Phone number is required');
          isValid = false;
        } else if (!phoneRegex.test(phoneNo)) {
          setPhoneError('Phone number must be 10 to 12 digits');
          isValid = false;
        }
      
        if (!message) {
          setMessageError('Message is required');
          isValid = false;
        }
      
        if (isValid) {
          try {
            const form = document.getElementById('contact-form');
            const result = await emailjs.sendForm(
              'service_a2r0n27',
              'template_0uir8e7',
               form,
              'S5JRYws8ySUwRvLBZ'
            );
      
            if (result.status === 200) {
              setNickName('');
              setNickNameError('');
              setPhoneNo('');
              setPhoneError('');
              setMessage('');
              setMessageError('');
              setIsSuccessMessage(true);
            }
          } catch (error) {
            console.error("There was an error submitting the form:", error);
          }
        }
      };
      


          useEffect(() => {
        if (isSuccessMessage) {
          const timer = setTimeout(() => {
            setIsSuccessMessage(false);
          }, 8000); // 30 seconds      
          return () => clearTimeout(timer);
        }
      }, [isSuccessMessage]);
    
      const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
          event.preventDefault();
        }
      };

    


    return (
        <AppBar position="static" sx={{height:{ xs: '68px', md: '74px' } , justifyContent: 'center',width:'100%',
        boxShadow: 'none',background: 'transparent', alignItems:'center'}}>
            <Container maxWidth={false}  sx={{ height: { xs: '62px', md: '74px' },width:{ xs: '98%', md: '95%' } ,}}>
                <Toolbar disableGutters sx={{ height: '100%',}}>
                <Box sx={{width: '100%' , height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection:'row' }}>                   
                <Box sx={{width: { xs: '92px',sm: '170px', md: '210px' } , height: { xs: '36px', sm: '36px', md: '58px' }, display: { xs: 'none',sm: 'flex' ,md: 'flex' },
                justifyContent: 'center', alignItems: 'center',}}>
                <img src={logo} alt="Logo" style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                </Box>
                <Box sx={{width: { xs: '110px', md: '24px' } , height: { xs: '52px', md: '24px' }, display: { xs:'flex',sm: 'none' , md:'none' },
                justifyContent: 'center', alignItems: 'center',}}>
                <img src={logo} alt="Logo" style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' }, width: '30%' }}>
                <IconButton size="large" aria-label="open drawer" onClick={handleOpenDrawer} sx={{ color: Colors.white }}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer}
                    sx={{'& .MuiDrawer-paper': {
                            backgroundColor: Colors.white,},
                        backdropFilter: 'none',}}
                        BackdropProps={{
                        sx: { backgroundColor: 'rgba(0, 0, 0, 0)' },}} >
                        <Box sx={{ display: 'flex' }}>
                            <IconButton aria-label="close drawer" onClick={handleCloseDrawer} 
                            sx={{ ml: 'auto', color: Colors.black_80 }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ width: 200 }}>
                            {pages.map((page) => {
                               
                                return (
                                    <Link
                                        key={page}
                                        to={`/${page.toLowerCase().replace(/\s+/g, '-')}`}
                                        style={{ textDecoration: 'none', color: 'inherit', gap:'8px' }}>
                                        <MenuItem
                                            selected={selectedPage === page}
                                            onClick={() => handleMenuItemClick(page)}
                                            sx={{
                                              color: currentPage === page ?  Colors.white :  Colors.black_80,
                                                textTransform: "none",
                                                background: currentPage === page ?  Colors.Red : Colors.white,
                                                fontFamily: "SF Pro Display", fontSize: "16px", 
                                                fontWeight:   400, minHeight:'auto' ,                                      
                                                '&:hover': { color: currentPage === page ?  Colors.white :  Colors.black_80, bgcolor: 'white', 
                                                fontWeight: "400" }}} >
                                            {page}
                                        </MenuItem>
                                    </Link>
                                );
                            })}
                        </Box>
                    </Drawer>
                </Box>
                
                <Box
    sx={{
        flexGrow: 1,
        display: { xs: 'none', sm: 'flex', md: 'flex' },
        height: '100%',
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
    }}
>
    {pages.map((page) => (
        <Button
            key={page}
            sx={{
                color: Colors.white,
                display: "block",
                textTransform: "none",
                fontFamily: 'SF Pro Display',
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 500,
                position: 'relative',
                textDecoration: 'none', // Remove default underline
                background: 'none',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: '0',
                    bottom: '0px',  // Position the underline at the bottom of the text
                    width: '100%',  // Make the underline as wide as the text
                    height: '2px',  // Adjust the thickness of the underline
                    backgroundColor: Colors.Red,  // Underline color
                    transform: 'scaleX(0)',  // Start with zero width
                    transformOrigin: 'bottom left',
                    transition: 'transform 0.4s ease',  // Animate the width
                },
                '&:hover::after': {
                    transform: 'scaleX(1)',  // Expand underline on hover
                },
                '&.active::after': {
                    transform: 'scaleX(1)',  // Ensure underline is visible for active page
                },
                '&:hover': {
                    color: Colors.white, 
                    bgcolor: 'none',
                },
            }}
            className={currentPage === page ? 'active' : ''}
        >
            <Link
                to={`/${page.toLowerCase().replace(/\s+/g, '-')}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                {page}
            </Link>
        </Button>
    ))}
</Box>

                <Box sx={{width:{ xs: '70%', md: '186px' }  , height: { xs: '36px', md: '40px' }, display: 'flex', justifyContent: 'right', 
                alignItems: 'center', }}>

               <ButtonRed onClick={handleGetInTouchButtonClick}  sx={{width:{ xs:'70%', md: '100% !important' }}}>Get in touch</ButtonRed> 
                </Box>
                </Box>
                </Toolbar>
            </Container>


            {isGetInTouch && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '200',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                            <IconButton onClick={handleCloseGetInTouchPopUp} sx={{
                        position: 'fixed', width: '36px', height: '36px',}} >
                            <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.Red, '&:hover': {
                            width: '40px', height: '40px', color: Colors.Red, backgroundColor: 'none',
                            background:'none'},}} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{width: { md: '35%', sm: '35%', xs: '85%' },height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                            boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'7%',
                                    justifyContent: 'center', alignContent: 'center', height: '86%',maxWidth:'86%',
                                    paddingBottom:'7%'
                                    }}>
                            {!isSuccessMessage && <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '20px',
                                justifyContent: 'center',  height: '100%', alignItems: 'left',
                            }}>
                                <TypographyComponent sx={{fontSize:'20px', fontWeight:500}}>
                                Get in touch with us
                                </TypographyComponent>  
                                <form onSubmit={handleSubmitButtonClick} id="contact-form">
                                 <Box sx={{ display: 'flex', flexDirection:  'column' , width: '100%', justifyContent: 'center', alignItems: 'stretch', gap: '12px' }}>
                                    <Box sx={{ gap: '4px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <TypographyComponent sx={{ fontSize: '16px', 
                                        color: Colors.black_80,fontWeight:400 }}>Nick name</TypographyComponent>
                                    <TextfieldComponent
                                        variant="outlined"
                                        value={nickName}
                                        name="nickName"
                                        placeholder="Enter your nick name"
                                        error={!!nickNameError}
                                        onChange={handleNickNameChange} 
                                        />
                                        {nickNameError && 
                                        <TypographyComponent sx={{color: Colors.Red}} >{nickNameError}</TypographyComponent>}
                                    </Box>    

                                    <Box sx={{ gap: '4px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <TypographyComponent sx={{ fontSize: '16px', 
                                        color: Colors.black_80,fontWeight:400 }}>Phone number</TypographyComponent>
                                    <TextfieldComponent
                                        variant="outlined"
                                        value={phoneNo}
                                        name="phoneNo" 
                                        placeholder="Enter your phone number"
                                        onChange={handlePhoneNoChange}
                                        onKeyDown={handleKeyDownPhone}
                                        inputProps={{ maxLength: 12 }}
                                        error={!!phoneError} 
                                        />
                                      {phoneError && <TypographyComponent sx={{color: Colors.Red}} >{phoneError}</TypographyComponent>}                                        
                                    </Box>    

                                     <Box sx={{ gap: '4px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <TypographyComponent sx={{ fontSize: '16px', 
                                        color: Colors.black_80,fontWeight:400 }}>Message</TypographyComponent>
                                     <TextField variant="outlined" multiline rows={3} placeholder="Type your queries"
                                                value={message}
                                                name="message"
                                                onChange={handleMessageChange}
                                                error={!!messageError}
                                                sx={{
                                                    height: '84px',
                                                    width: '100%',
                                                    padding: 0,
                                                    '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: '1px solid #c5c5c5',
                                                        borderColor: messageError ? Colors.Red : Colors.black_60,
                                                        transition: 'border-color 0.3s ease',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: messageError ? Colors.Red : Colors.black_80, 
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                    borderColor: messageError ? Colors.Red : Colors.black_80,
                                                    },
                                                    '& .MuiInputBase-input::placeholder': {
                                                    lineHeight:'18px',
                                                        fontFamily:'SF Pro Display',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: Colors.black_70,
                                                    },
                                                    '& .MuiInputBase-input': {                              
                                                        fontSize: '14px', lineHeight:'18px',
                                                        fontFamily:'SF Pro Display',
                                                        fontWeight: 400,
                                                        color: Colors.black_80,
                                                    },
                                                    },                           
                                                }}
                                            />
                                      {messageError && <TypographyComponent sx={{color: Colors.Red}} >{messageError}</TypographyComponent>}                                        
                                    </Box>                        

                                
                                    <Box sx={{
                                        width: '100%', height: '100%', paddingTop:'8px', justifyContent: 'right', alignItems: 'center',
                                        display: 'flex', flexDirection: 'row'
                                    }}>
                                     <ButtonRed type="submit" sx={{ width: '40%', gap: '4px' }}>Submit</ButtonRed>
                                    </Box>
                                </Box>
                                </form>
                            </Box>}

                            {isSuccessMessage &&
            <Box sx={{
              width: '100%', height: '420px', display: 'flex', justifyContent: 'center',
              alignItems: 'center', flexDirection: 'row', gap: '8px',
            }}>
              <Box sx={{
                width: '100%',  height: {xs:'180px',md:'74px',sm:'180px'}, display: 'flex', justifyContent: 'center',
                alignItems: 'center', flexDirection: 'column', gap: '16px',
                borderRadius: '4px'
              }}>
                <CheckCircleIcon style={{ color: '#0BEA14', borderRadius: '50%', fontSize:'48px'}} />
                <TypographyComponent sx={{
                 fontWeight: 400, fontSize: '18px', color: Colors.black_70, lineHeight:'28px', textAlign:'center'
                }}>
                  Your message has been sent successfully. We appreciate you reaching out to us. Our team will get back to you as soon as possible.
                </TypographyComponent>
              </Box>
            </Box>
             }  
                        </Box>
                    
                </Box>
                </Box>
                </Box>
                </Box>}


          
        </AppBar>
    );
}

export default Header;