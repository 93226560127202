

import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setToken(localStorage.getItem('token'));
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
