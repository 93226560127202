
import React from 'react';
import { Box, List, ListItem,Grid2 } from '@mui/material';
import Header from '../Components/Header';
import headerbg from '../Images/headerbg.png';
import bgmob from '../Images/bgmob.jpg';
import Colors from '../Components/Colors';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TypographyComponent from '../Components/TypographyComponent';
import { useMediaQuery } from '@mui/material';

const Service = () => {

  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const focusLabel = params.get('focus');
    if (focusLabel) {
      const element = document.getElementById(focusLabel);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  const listItems1 = ['PPC',
    'Content creation', 'UX- Audit',];

  const listItems2 = ['SEO', 'Email marketing', 'Lorem ipsum',];

  const listItems3 = ['Social media marketing', 'UI-UX Designing', 'Lorem ipsum'];
  const listItems1Mob = ['PPC', 'SEO', 'Social media marketing', 'Content creation',
    'Email marketing','UI-UX Designing','UX- Audit'];
 
  return (
    <Grid2 container sx={{
      width: '100%', height: '100%', overflow: 'hidden',
      justifyContent: 'center', alignItems: 'center'
    }}>
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center',
        alignItems: 'center', position: 'relative'
      }} >
        <Box sx={{
          width: '100%', height: '410px', backgroundImage: `url(${isDesktop ?headerbg:bgmob})`, backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', position: 'relative', zIndex: 1, justifyContent: 'center', display: 'flex',
          alignItems: 'center', flexDirection: 'column', gap: {sm:'54px',md:'54px',xs:'8px'}
        }} >
          <Box sx={{
            position: 'absolute', top: 0, left: 0, width: '100%',
            height: '100%', backgroundColor: 'rgba(45, 45, 45, 0.48)'
          }} />
          <Header currentPage={'Services'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 2 }} />
          <Box sx={{
            display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
            alignItems: 'center'
          }} >
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
              alignItems: 'center', maxWidth: '90%', maxHeight: '90%', gap: {md:'32px',sm:'32px',xs:'12px'}
            }} >

              <TypographyComponent sx={{
                fontWeight: 500, fontSize:{md:'48px',sm:'48px',xs:'32px'} , color: '#ffffff', zIndex: 9,
                width: '100%', lineHeight: '56px', textAlign: {xs:'start',md:'center',sm:'center'}, paddingLeft:{md:'0px',sm:'0px',xs:'24px'}
              }}>
                Our Services</TypographyComponent>


              <Box sx={{
                width: '100%', display: { md: 'flex', xs: 'none' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
                height: '100%', maxWidth: '90%',
              }}>
                <Box sx={{
                  width: 'calc(100%/3)', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex',
                  flexDirection: 'column', gap: '3px', minWidth: 'fit-content'
                }}>

                  <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '34px',
                    padding: 0
                  }}>
                    {listItems1.map((text, index) => (
                      <ListItem key={index} disablePadding
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        
                        <TypographyComponent sx={{ fontWeight: 500, fontSize: '24px', color: Colors.white }}>
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box sx={{
                  width: 'calc(100%/3)', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex', flexDirection: 'column',
                  gap: '3px', paddingLeft: '20%', minWidth: 'fit-content'
                }}>
                  <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '34px',
                    padding: 0
                  }}>
                    {listItems2.map((text, index) => (
                      <ListItem key={index} disablePadding
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        
                        <TypographyComponent
                          sx={{ fontWeight: 500, fontSize: '24px', color: Colors.white }}
                        >
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box sx={{
                  width: 'calc(100%/3)', height: '100%', justifyContent: 'right', alignItems: 'right', display: 'flex',
                  flexDirection: 'column', paddingLeft: '20%', minWidth: 'fit-content'
                }}>
                  <List
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '34px',
                      padding: 0
                    }}>
                    {listItems3.map((text, index) => (
                      <ListItem key={index} disablePadding
                        sx={{ display: 'flex', alignItems: 'center' }}>
                       
                        <TypographyComponent
                          sx={{ fontWeight: 500, fontSize: '24px', color: Colors.white }}
                        >
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>

              </Box>

              <Box sx={{
                width: '100%', display: { md: 'none', xs: 'flex' }, flexDirection: 'row',
                alignItems: 'center', height: '100%', maxWidth: '90%', gap: '10%', maxHeight: '90%',justifyContent:'flex-start'
              }}>
                <Box sx={{
                  width: '100%', height: '100%', justifyContent: 'flex-start', 
                  alignItems: 'flex-start', display: 'flex',
                  flexDirection: 'column', gap: '3px'
                }}>

                  <List>
                    {listItems1Mob.map((text, index) => (
                      <ListItem key={index} disablePadding  sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        marginBottom: index !== listItems1Mob.length - 1 ? '12px' : 0 // Add margin to all except the last item
                      }}>
                       
                        <TypographyComponent
                          sx={{ fontWeight: 500, minWidth: 'fit-content',
                            fontSize: '16px', color: Colors.white, lineHeight: '18px' }}
                        >
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>

      </Box>

      <Box sx={{
        height: '95%', display: 'flex', width: '100%', maxWidth: '90%', gap: {sm:'34px',md:'34px',xs:'16px'},
        paddingTop: {sm:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
      }}>
        <Box id="PPC" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight:{ sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Pay-Per-Click (PPC) Advertising
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Our PPC advertising services are designed to deliver targeted traffic and immediate results. By creating and managing highly effective ads, we ensure your brand reaches the right audience at the perfect moment. From comprehensive keyword research to strategic bid management, we optimize every aspect of your campaign to maximize your return on investment.
            We take a data-driven approach to PPC, continuously monitoring and refining your campaigns to boost visibility and drive conversions. Whether it’s Google Ads, social media platforms, or display networks, our goal is to help you achieve measurable success in a competitive digital landscape.
          </TypographyComponent>
        </Box>
        <Box id="SEO" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Search Engine Optimization (SEO)
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400,fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Boost your website's visibility with our expert SEO services. We specialize in optimizing your site to rank higher in organic search results, ensuring your business stands out when potential customers are searching for relevant keywords. Our approach includes a combination of on-page SEO, technical optimization, and strategic link building to improve your site’s performance. We provide ongoing SEO support, analyzing your site’s metrics and adjusting our strategies to adapt to the latest search engine algorithms. By focusing on sustainable growth, we help your website attract more traffic, generate leads, and establish a strong online presence over tim
          </TypographyComponent>
        </Box>
        <Box id="Social media marketing" sx={{ display: 'flex', flexDirection: 'column',gap: {sm:'16px',md:'16px',xs:'8px'}}}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight:{ sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Social Media Marketing
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Harness the full power of social media with our comprehensive marketing services. We manage all types of social media marketing to help you build a strong online presence, engage with your audience, and drive business growth. Our approach covers everything from creating and curating engaging content to managing social media ads and analyzing performance across platforms like Facebook, Instagram, Twitter, LinkedIn, and more. We tailor our strategies to fit your brand’s unique voice and objectives, ensuring that every campaign is designed to reach and resonate with your target audience. By leveraging the latest trends and tools, we help you maximize your social media impact, foster community engagement, and convert followers into loyal customers.
          </TypographyComponent>
        </Box>
        <Box id="Content creation" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'}}}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400},
           fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Content Creation
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Craft compelling stories and share your brand’s message with our content creation services. We develop a wide range of content, including blog posts, articles, infographics, and videos, all designed to captivate your audience and encourage them to take action. Our content is carefully aligned with your marketing goals, helping you build authority and trust within your industry. Our team of skilled writers and designers works closely with you to ensure that every piece of content reflects your brand’s values and engages your target audience. Whether you need content for your website, social media, or email campaigns, we create materials that leave a lasting impression.
          </TypographyComponent>
        </Box>
        <Box id="Email marketing" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight:{ sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Email Marketing
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Connect with your audience through personalized and effective email marketing campaigns. We help you nurture leads, promote products, and keep your customers informed with well-crafted emails that speak directly to their needs. Our services include everything from list segmentation and content creation to performance tracking and optimization. By focusing on delivering the right message at the right time, we ensure that your email campaigns achieve higher open rates and drive more conversions. Our goal is to help you build long-lasting relationships with your customers and keep your brand top of mind.
          </TypographyComponent>
        </Box>
        <Box id="UI-UX designing" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'}}}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            UI-UX Designing
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Deliver exceptional user experiences with our UI/UX design services. We focus on creating intuitive, user-friendly interfaces that are not only visually appealing but also functional. Our designs are rooted in a deep understanding of user behavior, ensuring that your website or app provides a seamless experience that meets your users' needs. From wireframes to final designs, we handle every aspect of the design process, ensuring that your digital products are both beautiful and easy to navigate. By prioritizing usability and accessibility, we help you reduce bounce rates, increase engagement, and enhance overall customer satisfaction.
          </TypographyComponent>
        </Box>
        <Box id="UI-UX audit" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
          <TypographyComponent component="h1" sx={{ fontWeight: {sm:500,md:500,xs:400}, fontSize: {sm:'32px',md:'32px',xs:'14px'}, 
          lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            UX - Audit
          </TypographyComponent>
          <TypographyComponent sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight:{ sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Optimize your digital products with a comprehensive UX audit. We thoroughly analyze your website or app to identify usability issues and areas for improvement. Our audit provides you with actionable insights that can enhance the user experience, leading to higher customer satisfaction and increased conversions. Our UX audit covers every aspect of your digital product, from navigation and layout to content and functionality. By addressing pain points and optimizing the user journey, we help you create a more effective and enjoyable experience for your customers.
          </TypographyComponent>
        </Box>
        <Box id="Lorem ipsum">Lorem ipsum Content</Box>
      </Box>



      <Footer currentPageFooter={'Services'}></Footer>
    </Grid2>

  );
};

export default Service;
