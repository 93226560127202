import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextareaAutosize, Button, Checkbox, IconButton, Typography, Paper,
    Box
} from '@mui/material';
import { API_URL } from '../components/Constants';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const LawnSignReport = () => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        fetch(`${API_URL}/displaynewview`)
            .then(response => {
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data)) {
                    setTableData(data);
                } else {
                    console.error('Data is not an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handlePostComment = (id, extraComment, resetExtraComment) => {
        if (!extraComment.trim()) {
            alert('Please enter a comment before posting.');
            return;
        }

        fetch(`${API_URL}/postComment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                extraComment: extraComment
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(result => {
                console.log('Success:', result);
                alert('Comment posted successfully');
                resetExtraComment();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Failed to post comment');
            });
    };

    return (
        <Box sx={{display:'flex',alignItems:'center',flexDirection:'column',paddingLeft:'4%',paddingRight:'4%'}}>
            <Box sx={{ display: 'flex', alignItems: "flex-start", width: '100%', }}>
        <IconButton color="primary" onClick={() => navigate('/links')}>
          <HomeIcon />
        </IconButton>
      </Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Report
            </Typography>
            <TableContainer component={Paper}>
                <Table id="results">
                    <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Id</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Extra Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((item, index) => (
                            <ReportRow 
                                key={item[3] || index} 
                                item={item} 
                                onPostComment={handlePostComment} 
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
    );
};

const ReportRow = ({ item, onPostComment }) => {
    const id = item[3];
    const imageLocations = item[0] ? item[0].split(',').map(url => url.trim()) : [];
    const createdAt = item[1];
    const comment = item[2];

    const [imageVisibility, setImageVisibility] = useState(imageLocations.map(() => false));
    const [extraComment, setExtraComment] = useState('');

    const toggleImageVisibility = (index) => {
        setImageVisibility(prev => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    const handleExtraCommentChange = (e) => {
        setExtraComment(e.target.value);
    };

    const handlePostClick = () => {
        onPostComment(id, extraComment, () => setExtraComment(''));
    };

    return (
        <TableRow>
            <TableCell>
                <Checkbox />
            </TableCell>
            <TableCell>{id}</TableCell>
            <TableCell>
                {imageLocations.map((location, idx) => (
                    <div key={idx} style={{ marginBottom: '10px' }}>
                        <Button 
                            variant="contained" 
                            size="small" 
                            onClick={() => toggleImageVisibility(idx)} 
                        >
                            {imageVisibility[idx] ? `Hide Image ${idx + 1}` : `Show Image ${idx + 1}`}
                        </Button>
                        {imageVisibility[idx] && (
                            <img 
                                src={location} 
                                alt={`Image ${idx + 1}`} 
                                style={{ maxWidth: '500px', height: 'auto', marginTop: '10px', display: 'block' }}
                            />
                        )}
                    </div>
                ))}
            </TableCell>
            <TableCell>{createdAt}</TableCell>
            <TableCell>
                <TextareaAutosize 
                    value={comment} 
                    readOnly 
                    minRows={4} 
                    style={{ width: '100%', padding: '5px' }} 
                />
            </TableCell>
            <TableCell>
                <TextareaAutosize 
                    value={extraComment} 
                    onChange={handleExtraCommentChange} 
                    minRows={4} 
                    placeholder="Enter your comment here..." 
                    style={{ width: '100%', padding: '5px' }} 
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    onClick={handlePostClick} 
                    style={{ marginTop: '5px' }}
                >
                    Post
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default LawnSignReport;
