const Colors = {

    /*** Primary colors ***/
    Red: '#DD223F',
    Black: '#132436',
    secondary_clr: '#3B4956',    

    /*** Hovering colors ***/
    hovering_red: '#B90C27',  
    
    /*** black shades ***/
    black_90: '#201E1E',
    black_80: '#313131',
    black_70: '#616161',
    black_60: '#C5C5C5',

    /*** light shades ***/  
    grey_20: '#F9F9F9',
    white_80: '#F5F5F5',
    white: '#FFFFFF',
    linkblue: '#2D96D1',

  };
  
  export default Colors;